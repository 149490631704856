import styled, { CSSProperties } from 'styled-components';

import fonts from '@pickles/shared/utils/fonts';

import sizes from '../../util/sizes';

export const Container = styled.div`
  max-width: 370px;
`;

interface ISubText {
  mobileTextAlign?: CSSProperties['textAlign'];
  marginTop?: number;
}
export const SubText = styled.div<ISubText>`
  font-size: 20px;
  font-family: ${fonts.BC_SEMI_BOLD_FONT};
  font-weight: 600;
  margin-top: ${({ marginTop = 0 }) => marginTop}px;
  text-align: center;
  @media (max-width: ${sizes.S}) {
    text-align: ${({ mobileTextAlign = 'center' }) => mobileTextAlign};
  }
`;

interface ISmalltext {
  mobileTextAlign?: CSSProperties['textAlign'];
  marginTop?: number;
}
export const SmallText = styled.div<ISmalltext>`
  font-family: ${fonts.BC_FONT};
  font-weight: 100;
  font-size: 13px;
  text-align: center;
  margin-top: ${({ marginTop = 0 }) => marginTop}px;
  @media (max-width: ${sizes.S}) {
    text-align: ${({ mobileTextAlign = 'center' }) => mobileTextAlign};
  }
`;