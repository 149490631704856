import styled from 'styled-components';

import colors from '@pickles/shared/utils/colors';
import fonts from '@pickles/shared/utils/fonts';

export const Container = styled.div`
  width: 100%;
  padding: 0 20px;
`;

export const Row = styled.div`
  width: 100%;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid ${colors.mischka};
  color: ${colors.rhino_black};
  > div {
    font-family: ${fonts.BC_FONT};
  }
`;
export const Header = styled.div`
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 6px;
`;
export const Content = styled.div`
  font-size: 17px;
`;