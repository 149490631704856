import React, { useState } from 'react';
import styled from 'styled-components';

import sizes from '../../util/sizes';

import { ProfileTabPanel } from './ProfileTabPanel';
import { Profile } from './Profile';
import { Transactions } from './Transactions';
import { EInvoicingDetails } from './EInvoicingDetails';

export enum ProfileTabNames {
  Transactions = 'Transactions',
  Profile = 'Profile',
  'E-Invoicing details' = 'E-Invoicing details',
}

export const ProfileContent = () => {
  const [activeTab, setActiveTab] = useState(ProfileTabNames.Transactions);

  const onClick = (tab: ProfileTabNames) => setActiveTab(tab);

  const renderActiveTab = (activeTab: ProfileTabNames) => {
    switch (activeTab) {
      case ProfileTabNames.Transactions:
        return <Transactions />;
      case ProfileTabNames.Profile:
        return <Profile />;
      case ProfileTabNames['E-Invoicing details']:
        return <EInvoicingDetails />;
      default:
        return <Transactions />;
    }
  };

  return (
    <ProfileContentContainer>
      <ProfileTabPanel onClick={onClick} activeTab={activeTab} />
      {renderActiveTab(activeTab)}
    </ProfileContentContainer>
  );
};

const ProfileContentContainer = styled.div`
  margin: 0 auto 112px;
  max-width: ${sizes.XL};
`;