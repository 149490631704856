import { FC, useCallback, useLayoutEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { api } from '@pickles/shared/services/api';
import { CountriesResponseData } from "@pickles/shared/services/api/response.types";
import { USER_ACCOUNT_TYPE } from "@pickles/shared/models/user.types";

import { UserHooks } from "../../app/redux/hooks";

import { Container, Content, Header, Row } from "./EInvoicingInfo.style";

export const EInvoicingInfo: FC = () => {
  const [countries, setCountries] = useState<CountriesResponseData>([]);

  const { t } = useTranslation();
  const account = UserHooks.useUserAccount();

  const {
    accType,
    nationality,
    incorpCountry,
    tinId,
    sstId,
    brnId,
  } = account ?? {
    accType: null,
    nationality: null,
    incorpCountry: null,
    tinId: null,
    sstId: null,
    brnId: null,
  };
  
  const isBusinessAccount = accType === USER_ACCOUNT_TYPE.BUSINESS;

  const fetchCountries = useCallback(async () => {
    // @ts-ignore
    const response: CountriesResponse = await api.getCountries();
    if (response.ok && response.data && response.data.length) {
      setCountries(response.data);
    }
  }, []);

  const getCountryName = useCallback((countryCode: string) => {
    const country = countries.find((c: any) => c.code === countryCode);
    return country ? country.value : '';
  }, [countries]);

  useLayoutEffect(() => {
    fetchCountries();
  }, [fetchCountries]);
  
  return (
    <Container>
      <InfoRow label={t('labels:reg_as')} text={t(`fields:acc_type_${accType!.toLowerCase()}`)} />
      {isBusinessAccount ? (
        <InfoRow label={t('labels:incorp_country')} text={getCountryName(incorpCountry || '')}/>
      ) : (
        <InfoRow label={t('labels:nationality')} text={getCountryName(nationality || '')}/>
      )}
      <InfoRow label={t('labels:tin_id')} text={tinId || ''}/>
      {isBusinessAccount && (
        <>
          <InfoRow label={t('labels:sst_id')} text={sstId || ''}/>
          <InfoRow label={t('labels:brn_id')} text={brnId || ''}/>
        </>
      )}
    </Container>
  )
};

interface InfoRowProps {
  label: string;
  text: string;
}

const InfoRow: FC<InfoRowProps> = ({ label, text }) => {
  return (
    <Row>
      <Header>{label}</Header>
      <Content>{text}</Content>
    </Row>
  )
};