import styled from 'styled-components';

import sizes from '../../util/sizes';

export const EInvoicingDetailsContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 32px;
  @media (max-width: ${sizes.S}) {
    padding-top: 0px;
  }
`;