import { FC } from "react";
import { useDispatch } from 'react-redux';
import { useTranslation } from "react-i18next";
import { useFormik } from 'formik';

import { EInvoicingData, USER_ACCOUNT_TYPE } from "@pickles/shared/models/user.types";
import { userActions } from "@pickles/shared/redux/actions";

import { EInvoicingSchema } from "../EInvoicingForm/EInvoicing.schema";

import { Container, SmallText, SubText } from "./EInvoicingUpdateForm.style";
import Button from "../Shared/Button/Button";
import { EInvoicingForm } from "../EInvoicingForm";

const initialValues: EInvoicingData = {
  accType: USER_ACCOUNT_TYPE.INDIVIDUAL,
  nationality: null,
  incorpCountry: null,
  tinId: null,
  sstId: null,
  brnId: null,
};

export const EInvoicingUpdateForm: FC = ({}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    values: {
      accType,
      nationality,
      incorpCountry,
      tinId,
      sstId,
      brnId,
    },
    handleChange,
    handleSubmit,
    errors,
  } = useFormik<EInvoicingData>({
    initialValues,
    onSubmit: (values) => {
      dispatch(userActions.updateUser(values));
    },
    validationSchema: EInvoicingSchema,
    validateOnChange: false,
  });

  return (
    <Container>
      <SubText mobileTextAlign={'left'}>{t('titles:submit_tax_info')}</SubText>
      <SmallText mobileTextAlign={'left'} marginTop={8}>
        {t('infos:submit_tax_info')}
      </SmallText>
      <EInvoicingForm
        accType={accType}
        nationality={nationality}
        incorpCountry={incorpCountry}
        tinId={tinId}
        sstId={sstId}
        brnId={brnId}
        handleChange={handleChange}
        errors={errors}
      />
      <SmallText mobileTextAlign={'left'} marginTop={32}>
        {t('infos:ensure_tax_info_accurate_to_irb')}
      </SmallText>
      <Button
        text={t('buttons:save')}
        height={'52'}
        marginTop={'32'}
        marginBottom={'0'}
        id={'save'}
        type={'button'}
        onBtnClick={handleSubmit}
      />
    </Container>
  );
};