import { FC } from "react";
import { useTranslation } from "react-i18next";

import { LoaderHooks, UserHooks } from "../../app/redux/hooks";

import { EInvoicingDetailsContainer } from "./EInvoicingDetails.style";
import MobileHeaderLine from "../Shared/MobileHeaderLine";
import Loading from "../Shared/Loading";
import { EInvoicingUpdateForm } from "./EInvoicingUpdateForm";
import { EInvoicingInfo } from "./EInvoicingInfo";

interface EInvoicingDetailsProps {
  closeScreen?: () => void;
  isMobile?: boolean;
}

export const EInvoicingDetails: FC<EInvoicingDetailsProps> = ({ closeScreen, isMobile }) => {
  const account = UserHooks.useUserAccount();
  const {
    accType,
  } = account ?? {
    accType: null,
  };

  const isUpdatingUser = LoaderHooks.useLoadingIndicator('updateUser');
  const isGettingAccount = LoaderHooks.useLoadingIndicator('account');
  const isLoading = isUpdatingUser || isGettingAccount;

  if (isLoading) {
    return (
      <ContentWrapper closeScreen={closeScreen} isMobile={isMobile}>
        <Loading id='e_invoicing_details_loader' />
      </ContentWrapper>
    );
  }

  return (
    <ContentWrapper closeScreen={closeScreen} isMobile={isMobile}>
      {accType ? <EInvoicingInfo /> : <EInvoicingUpdateForm />}
    </ContentWrapper>
  );
};

interface ContentWrapperProps {
  closeScreen?: () => void;
  isMobile?: boolean;
  children: React.ReactNode;
}

const ContentWrapper: FC<ContentWrapperProps> = ({ closeScreen, isMobile, children }) => {
  const { t } = useTranslation();

  return (
    <>
      {isMobile && closeScreen && (
        <MobileHeaderLine
          hasShareBtn={false}
          onBackBtnClick={closeScreen}
          title={t('titles:e_invoicing_details')}
          isRunningLiveAuction={false}
        />
      )}
      <EInvoicingDetailsContainer>
        {children}
      </EInvoicingDetailsContainer>
    </>
  );
};